import React from 'react';
import { Modal } from '../../components/molecules/Modal';
import { Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';
import PropTypes from 'prop-types';
import { IndicatorHistoryProgressForm } from './IndicatorHistoryProgressForm';
import {
  useConfirmProgressIndicator,
  useGetIndicatorProgressDetail,
  useRejectProgressIndicator
} from '../../queries/queryIndicatorProgresses';
import { LoadingCircularProgress } from '../../components/atoms/LoadingCircularProgress';
import { STATUS } from './IndicatorDetailTable';
import { Avatar, AVATAR_VARIANTS, SIZE } from '../../components/atoms/Avatar';
import { ListDownloadDocuments } from '../../components/organims/ListDownloadDocuments';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = makeStyles({
  progressBar: {
    gap: lightThemeV2.spacing(2),
    '& *[class*=MuiLinearProgress-root]': {
      borderRadius: '4px',
      height: '8px'
    },
    '& *[class*="MuiLinearProgress-bar1Determinate"]': {
      backgroundColor: palette.captuDataYellow
    }
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: lightThemeV2.spacing(2)
  }
});

export const IndicatorHistoryProgressModal = ({ open, onClose, indicatorProgress = {}, indicator = {}, onSuccess = () => {} }) => {
  const { messages: intlMessages } = useIntl();
  const classes = styles();
  const { id: indicatorProgressId, indicatorId } = indicatorProgress;
  const { data: indicatorProgressDetail = {}, isLoading } = useGetIndicatorProgressDetail({ indicatorProgressId, indicatorId });
  const { mutateAsync: confirmProgressIndicator } = useConfirmProgressIndicator();
  const { mutateAsync: rejectProgressIndicator } = useRejectProgressIndicator();

  if (isLoading) {
    return (
      <Grid container>
        <LoadingCircularProgress />
      </Grid>
    );
  }

  const handleSubmit = async values => {
    if (values.status === STATUS.APPROVED) {
      await confirmProgressIndicator({ indicatorProgressId, indicatorId, ...values });
    } else if (values.status === STATUS.REJECTED) {
      await rejectProgressIndicator({ indicatorProgressId, indicatorId, ...values });
    }
    onSuccess();
    onClose();
  };

  const progressValue =
    indicatorProgressDetail.value <= indicator.goal
      ? (((indicatorProgressDetail.value || 0) * 100) / (indicator.goal || 100)).toFixed(2)
      : 100;

  return (
    <Modal
      open={open}
      onClose={onClose}
      tagtitle={<FormattedMessage id="project.indicator.progress.history" />}
      title={
        <Typography style={{ fontWeight: 900 }} variant="h6">
          {indicatorProgressDetail.indicator.name}
        </Typography>
      }
      subtitle={
        <Grid container direction="row" alignItems="center" className={classes.progressBar} wrap="nowrap">
          <Grid item>
            <Typography style={{ whiteSpace: 'nowrap' }}>
              <FormattedMessage id="mainContractStory.current_status" />
            </Typography>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progressValue} />
          </Grid>
          <Grid item>
            <Typography style={{ whiteSpace: 'nowrap' }}>{(indicatorProgressDetail.indicator || {}).goalHuminized}</Typography>
          </Grid>
        </Grid>
      }
    >
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'body1'}>
            <FormattedMessage id="mainContractStory.progress_date" />
          </Typography>
          <Typography variant={'body2'}>{indicatorProgressDetail.effectiveDate}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'}>
            <FormattedMessage id="project.indicator.progress.made" />
          </Typography>
          <Typography variant={'body2'}>
            {indicatorProgressDetail.value} {intlMessages['common.of']} {indicatorProgressDetail.indicator.goalHuminized}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'}>
            <FormattedMessage id="common.comments" />
          </Typography>
          <Typography variant={'body2'}>{indicatorProgressDetail.description}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.avatarContainer}>
          <Avatar responsible={indicatorProgressDetail.responsible.fullName} size={SIZE.SMALL} variant={AVATAR_VARIANTS.SECONDARY} />
          <Typography variant="body1">{indicatorProgressDetail.responsible.fullName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <ListDownloadDocuments indicatorProgressDetail={indicatorProgressDetail} />
        </Grid>
        <Grid item xs={12}>
          <IndicatorHistoryProgressForm data={indicatorProgressDetail} onClose={onClose} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </Modal>
  );
};

IndicatorHistoryProgressModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  indicatorProgress: PropTypes.object.isRequired,
  indicator: PropTypes.object.isRequired
};
