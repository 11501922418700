import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ImageSlider } from './ImageSlider';
import { useGetPreviewIndicatorProgressDocumentBulk } from '../../queries/queryIndicatorProgresses';

export const IndicatorProgressImageSlider = ({ documents = [], indicatorProgressId }) => {
  const { mutateAsync: getPreviewDocuments } = useGetPreviewIndicatorProgressDocumentBulk();
  const [preview, setPreview] = React.useState([]);

  useEffect(() => {
    const fetchPreview = async () => {
      const documentIds = documents.map(item => item.id);
      const previewBlob = await getPreviewDocuments({ indicatorProgressId, documentIds });
      const previewData = documents.map((item, index) => ({
        title: item.name,
        img: URL.createObjectURL(previewBlob[index])
      }));

      setPreview(previewData);
    };

    fetchPreview();
  }, [documents, indicatorProgressId, getPreviewDocuments]);

  return <ImageSlider itemData={preview} />;
};

IndicatorProgressImageSlider.propTypes = {
  documents: PropTypes.array,
  indicatorProgressId: PropTypes.number
};
