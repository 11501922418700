import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { palette } from '../../settings/themes/colors';

const useStyles = makeStyles(theme => ({
  root: {
    height: '90px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  scrollTrack: {
    width: '100%',
    height: '8px',
    overflowX: 'auto',
    marginBottom: '1px',
    paddingTop: '8px',
    '&::-webkit-scrollbar': {
      height: '8px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.default
    },
    '&::-webkit-scrollbar-thumb': {
      background: palette.dimGray,
      borderRadius: '4px'
    }
  },
  contentContainer: {
    width: '100%',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    minWidth: 'max-content'
  },
  selectedImage: {
    marginBottom: theme.spacing(2),
    '& img': {
      width: '100%',
      height: '300px',
      objectFit: 'contain',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default
    }
  }
}));

export const ImageSlider = ({ itemData = [] }) => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(null);
  const [contentWidth, setContentWidth] = useState('100%');
  const calculateCols = () => {
    return Math.min(itemData.length, 4);
  };
  const [columns, setColumns] = useState(calculateCols);
  const scrollTrackRef = React.useRef();
  const contentRef = React.useRef();

  useEffect(() => {
    if (itemData.length > 0) {
      setSelectedImage(itemData[0]);
    }
    setColumns(calculateCols);
  }, [itemData]);

  useEffect(() => {
    const updateContentWidth = () => {
      if (contentRef.current) {
        setContentWidth(`${contentRef.current.scrollWidth}px`);
      }
    };

    updateContentWidth();

    const resizeObserver = new ResizeObserver(updateContentWidth);

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        resizeObserver.unobserve(contentRef.current);
      }
    };
  }, [itemData]);

  const handleImageClick = image => {
    setSelectedImage(image);
  };

  const handleScroll = e => {
    const { scrollLeft } = e.target;

    if (e.target === scrollTrackRef.current) {
      if (contentRef.current) {
        contentRef.current.scrollLeft = scrollLeft;
      }
    } else if (scrollTrackRef.current) {
      scrollTrackRef.current.scrollLeft = scrollLeft;
    }
  };

  return (
    <>
      {selectedImage && (
        <div className={classes.selectedImage}>
          <img src={selectedImage.img} alt={selectedImage.title || 'Selected Image'} />
        </div>
      )}
      <div className={classes.scrollTrack} ref={scrollTrackRef} onScroll={handleScroll}>
        <div style={{ width: contentWidth, height: '1px' }} />
      </div>
      <div className={classes.root}>
        <div className={classes.contentContainer} ref={contentRef} onScroll={handleScroll}>
          <ImageList className={classes.imageList} cols={columns}>
            {itemData.map(item => (
              <ImageListItem
                key={item.img}
                onClick={() => handleImageClick(item)}
                style={{ cursor: 'pointer', width: '200px', flexShrink: 0 }}
              >
                <img src={item.img} alt={item.title || 'Image'} />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </div>
    </>
  );
};

ImageSlider.propTypes = {
  itemData: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string.isRequired,
      title: PropTypes.string
    })
  ).isRequired
};
