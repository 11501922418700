import { FormControl, FormControlLabel, FormHelperText, Grid, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { useFormik } from 'formik';
import { MappingFormFields } from '../../components/organims/MappingFormFields';
import { FormattedMessage } from 'react-intl';
import Button from '../../components/button';
import PropTypes from 'prop-types';
import { STATUS } from './IndicatorDetailTable';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = makeStyles({
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 42,
    marginBottom: lightThemeV2.spacing(2),
    '& *:first-child': {
      marginRight: lightThemeV2.spacing(2)
    }
  },
  readOnlyObservation: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: lightThemeV2.spacing(2)
  },
  buttonContainer: {
    gap: lightThemeV2.spacing(2),
    paddingTop: lightThemeV2.spacing(2)
  }
});

export const IndicatorHistoryProgressForm = ({ data, onClose, onSubmit = () => {} }) => {
  const classes = styles();

  const initialValues = {
    documents: data.documents || [],
    status: data.status || '',
    observation: data.observation || ''
  };
  const { getFieldProps, errors, handleSubmit } = useFormik({
    initialValues,
    onSubmit
  });

  const formFields = [
    {
      name: 'status',
      component: ({ required, error, helperText, ...props }) => {
        return (
          <FormControl>
            <RadioGroup row required={required} {...props}>
              <FormControlLabel value="approved" control={<Radio />} label={<FormattedMessage id="common.approved" />} />
              <FormControlLabel value="rejected" control={<Radio />} label={<FormattedMessage id="common.rejected" />} />
            </RadioGroup>
            {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
          </FormControl>
        );
      }
    },
    {
      label: <FormattedMessage id="project.inspection.observation" />,
      name: 'observation',
      multiline: true,
      minRows: 4,
      xs: 12
    }
  ];

  return (
    <div>
      <Typography variant="body2">
        <FormattedMessage id="project.indicator.progress.review.title" />
      </Typography>
      {data.status === STATUS.APPROVED && (
        <Grid container>
          <Grid item xs={12} className={classes.statusContainer}>
            <CheckCircleIcon style={{ color: '#43BC64' }} />
            <Typography variant="body2">
              <FormattedMessage id="common.approved" />
            </Typography>
          </Grid>
        </Grid>
      )}
      {data.status === STATUS.REJECTED && (
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.statusContainer}>
            <CancelIcon style={{ color: '#F13434' }} />
            <Typography variant="body2">
              <FormattedMessage id="common.rejected" />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.readOnlyObservation}>
            <Typography>
              <FormattedMessage id="project.inspection.observation" />
            </Typography>
            <Typography variant="body2">{data.observation}</Typography>
          </Grid>
        </Grid>
      )}
      {data.status === STATUS.DRAFT && <MappingFormFields fields={formFields} getFieldProps={getFieldProps} errors={errors} />}
      <Grid container className={classes.buttonContainer}>
        {data.status === STATUS.DRAFT && (
          <Grid item>
            <Button variant="contained" onClick={handleSubmit}>
              <FormattedMessage id="project.indicator.progress.confirm.button" />
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="outlined" onClick={onClose}>
            {data.status === STATUS.APPROVED || data.status === STATUS.REJECTED ? (
              <FormattedMessage id="common.close" />
            ) : (
              <FormattedMessage id="common.cancel" />
            )}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

IndicatorHistoryProgressForm.propTypes = {
  data: PropTypes.shape({
    effectiveDate: PropTypes.string,
    description: PropTypes.string,
    responsible: PropTypes.shape({
      fullName: PropTypes.string
    }),
    documents: PropTypes.array,
    observation: PropTypes.string,
    status: PropTypes.string
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
