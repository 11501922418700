import { isArray } from 'lodash';
import { useIntl } from 'react-intl';
import { replaceStrings } from '../utils/utils';

const objectTransformSnake2Camel = obj => {
  const outputObject = {};

  Object.keys(obj).forEach(key => {
    const transformedKey = key.replace(/_\w/g, match => match[1].toUpperCase());
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      if (isArray(value)) {
        outputObject[transformedKey] = value.map(item => objectTransformSnake2Camel(item));
      } else {
        outputObject[transformedKey] = objectTransformSnake2Camel(value);
      }
    } else {
      outputObject[transformedKey] = value;
    }
  });
  return outputObject;
};

export const transformSnake2Camel = entry => {
  if (isArray(entry)) {
    return entry.map(item => objectTransformSnake2Camel(item));
  }

  return objectTransformSnake2Camel(entry);
};

const objectTransformCamel2Snake = obj => {
  const outputObject = {};

  Object.keys(obj).forEach(key => {
    const transformedKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
    const value = obj[key];

    if (isArray(value)) {
      outputObject[transformedKey] = value.map(item => transformCamel2Snake(item));
    } else if (typeof value === 'object' && value !== null) {
      outputObject[transformedKey] = transformCamel2Snake(value);
    } else {
      outputObject[transformedKey] = value;
    }
  });
  return outputObject;
};

export const transformCamel2Snake = entry => {
  if (isArray(entry)) {
    return entry.map(item => objectTransformCamel2Snake(item));
  }

  return objectTransformCamel2Snake(entry);
};

export const useRecordTransform = () => {
  const { messages: intlMessages } = useIntl();

  const recordsItemTypes = Object.freeze({
    create: {
      Note: intlMessages['historic.event.note.created'],
      Contact: intlMessages['historic.event.beneficiary.created'],
      ContactGroup: intlMessages['historic.event.group.created'],
      Document: replaceStrings(intlMessages['historic.event.document.created'], [1, ' '])
    },
    update: {
      Note: intlMessages['historic.event.note.updated'],
      Contact: intlMessages['historic.event.beneficiary.updated'],
      ContactGroup: intlMessages['historic.event.group.updated']
    }
  });

  const transformRecordToItemData = (records = []) =>
    records.map(({ event, itemType, createdAt }) => ({
      event: (recordsItemTypes[event] || {})[itemType] || `${itemType} ${event}`,
      link: null,
      createdAt
    }));

  return { transformRecordToItemData };
};

export const downloadFile = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
