import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { downloadFile, transformCamel2Snake, transformSnake2Camel } from './utils';

export const useCreateIndicatorProgress = config =>
  useMutation(async ({ indicatorId, indicatorProgress }) => {
    const { effective_date, description, value, documents = [] } = indicatorProgress;

    const formData = new FormData();
    formData.append('indicator_progress[effective_date]', effective_date);
    formData.append('indicator_progress[description]', description);
    formData.append('indicator_progress[value]', value);

    documents.forEach(item => {
      formData.append(`indicator_progress[documents][][name]`, item.name);
      formData.append(`indicator_progress[documents][][file]`, item.file);
      formData.append(`indicator_progress[documents][][category_ids][]`, JSON.stringify(item.categoryIds[0]));
    });

    const axiosResult = await getApi().post(`/indicators/${indicatorId}/indicator_progresses`, formData);
    return axiosResult.data;
  }, config);

export const useGetIndicatorProgresses = indicatorId => {
  const queryFn = async context => {
    const [indicatorId] = context.queryKey;
    const result = await getApi().get(`/indicators/${indicatorId}/indicator_progresses`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryFn,
    queryKey: [indicatorId, 'GET_IndicatorProgresses'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useDownloadIndicatorProgressDocuments = () =>
  useMutation(({ indicatorId, indicatorProgressId }) =>
    getApi()
      .get(`/indicators/${indicatorId}/indicator_progresses/${indicatorProgressId}/download_documents`, {
        responseType: 'blob'
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Cant download documents');
        }
        downloadFile(response.data, `${indicatorId}-${indicatorProgressId}-documents.zip`);
      })
  );

export const useDownloadIndicatorProgressDocument = () =>
  useMutation(({ indicatorProgressId, documentId }) =>
    getApi()
      .get(`/indicator_progresses/${indicatorProgressId}/document/${documentId}/download`, {
        responseType: 'blob'
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Cant download document');
        }
        downloadFile(response.data, `${indicatorProgressId}-${documentId}_document`);
      })
  );

export const useGetPreviewIndicatorProgressDocumentBulk = () =>
  useMutation(async ({ indicatorProgressId, documentIds }) =>
    Promise.all(
      documentIds.map(documentId =>
        getApi().get(`/indicator_progresses/${indicatorProgressId}/document/${documentId}/download`, { responseType: 'blob' })
      )
    ).then(filesReponses => {
      filesReponses.forEach(response => {
        if (response.status !== 200) {
          throw new Error('Cant download document');
        }
      });

      return filesReponses.map(response => response.data);
    })
  );

export const useGetIndicatorProgressDetail = ({ indicatorId, indicatorProgressId }, config = {}) => {
  const queryFn = async context => {
    const [indicatorId, indicatorProgressId] = context.queryKey;
    const result = await getApi().get(`/indicators/${indicatorId}/indicator_progresses/${indicatorProgressId}`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryFn,
    queryKey: [indicatorId, indicatorProgressId, 'GET_IndicatorProgress'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...config
  });
};

export const useConfirmProgressIndicator = (options = {}) =>
  useMutation(
    ({ indicatorId, indicatorProgressId }) =>
      getApi()
        .put(`/indicators/${indicatorId}/indicator_progresses/${indicatorProgressId}`, {
          indicator_progress: { status: 'approved' }
        })
        .then(axiosResult => axiosResult.data),
    options
  );

export const useRejectProgressIndicator = (options = {}) =>
  useMutation(
    ({ indicatorId, indicatorProgressId, ...values }) =>
      getApi()
        .put(`/indicators/${indicatorId}/indicator_progresses/${indicatorProgressId}`, {
          indicator_progress: { status: 'rejected', observation: values.observation }
        })
        .then(axiosResult => axiosResult.data),
    options
  );
