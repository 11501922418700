import React from 'react';
import { NativeDatePicker } from '../molecules/NativeDatePicker';
import { MappingFormFields } from './MappingFormFields';
import { useFormik } from 'formik';
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { object, string } from 'yup';
import moment from 'moment';
import { SIMPLE_DASH_DATE_FORMAT } from '../../utils/utils';
import { ErrorMessage } from './ErrorMessage';
import Button from '../button';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { WrapRequiredLabel } from '../atoms/WrapRequiredLabel';
import { removeDecimalsFromAmount } from '../../containers/Projects/components/project/utils';
import { DragAndDropField } from '../../containers/Projects/components/project/Budget/DragAndDropField';
import PropTypes from 'prop-types';

const styles = makeStyles({
  buttonContainer: {
    gap: lightThemeV2.spacing(2),
    paddingTop: lightThemeV2.spacing(2)
  }
});

export const RegisterProgressIndicatorForm = ({ onCancel = () => {}, onSubmit, indicator = {} }) => {
  const classes = styles();
  const { messages: intlMessages } = useIntl();

  const normalizedRestProgress = indicator.goal - indicator.totalProgress;

  const restUnity = (
    <FormattedMessage
      id="project.indicator.progress.form.value"
      values={{
        restUnity: normalizedRestProgress < 0 ? 0 : normalizedRestProgress,
        measurementUnit: indicator.measurementUnit
      }}
    />
  );

  const initialValues = {
    effectiveDate: moment().format(SIMPLE_DASH_DATE_FORMAT),
    value: '',
    documents: [],
    description: ''
  };

  const validationSchema = object().shape({
    effectiveDate: string().required(<FormattedMessage key={'effectiveDate'} id="common.field.required" />),
    value: string()
      .required(<FormattedMessage key={'value'} id="common.field.required" />)
      .test('must-be-number', intlMessages['common.error.message.number'], testingValue => {
        return !isNaN(testingValue);
      })
      .test('must-be-greater-that-zero', intlMessages['common.field.greaterThatZero'], testingValue => {
        const currentTestingValue = removeDecimalsFromAmount(testingValue);
        return currentTestingValue > 0;
      })
  });

  const { getFieldProps, errors, setFieldValue, isValid, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitValues => {
      const { effectiveDate, description, value, documents, ...otherValues } = submitValues;

      onSubmit({
        ...otherValues,
        effectiveDate,
        description,
        value,
        documents
      });
    }
  });

  const formFields = [
    {
      label: <WrapRequiredLabel label={<FormattedMessage id="mainContractStory.progressDate" />} />,
      name: 'effectiveDate',
      component: NativeDatePicker,
      onChange: date => {
        setFieldValue('effectiveDate', date.format(SIMPLE_DASH_DATE_FORMAT));
      },
      InputProps: { inputProps: { max: moment().format(SIMPLE_DASH_DATE_FORMAT) } },
      xs: 12
    },
    {
      name: 'value',
      component: ({ label, ...props }) => (
        <Grid container wrap="nowrap">
          <Grid item>{label}</Grid>
          <Grid item style={{ display: 'flex', gap: '10px', alignItems: 'baseline' }}>
            <TextField label={<WrapRequiredLabel label={<FormattedMessage id="project.indicator.progress.made" />} />} {...props} />
            <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
              {restUnity}
            </Typography>
          </Grid>
        </Grid>
      ),
      xs: 12
    },
    {
      label: <FormattedMessage id="common.comments" />,
      name: 'description',
      multiline: true,
      placeholder: intlMessages['project.indicator.details.register.subtitle.addComment'],
      minRows: 4,
      xs: 12
    },
    {
      label: (
        <Grid direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body2">
              <FormattedMessage id="project.inspection.addEvidence" />
            </Typography>
          </Grid>
          <Grid item style={{ fontSize: 12 }}>
            <FormattedMessage id="project.indicator.details.register.subtitle.addDocument" />
          </Grid>
        </Grid>
      ),
      name: 'documents',
      onChange: documents => {
        setFieldValue('documents', documents);
      },
      component: ({ label, ...props }) => (
        <Grid container direction="column" spacing={1}>
          <Grid item> {label}</Grid>
          <Grid item>
            <DragAndDropField {...props} />
          </Grid>
        </Grid>
      ),
      xs: 12
    },
    {
      component: ErrorMessage,
      error: errors.documents,
      id: 'project.budget.invoice.register.form.filedRequired',
      xs: 12
    }
  ];

  return (
    <div>
      <MappingFormFields fields={formFields} getFieldProps={getFieldProps} errors={errors} />
      <Grid container spacing={2} className={classes.buttonContainer}>
        <Grid item>
          <Button variant="contained" type="button" disabled={!isValid} onClick={handleSubmit}>
            <FormattedMessage id="project.indicator.details.register.advanceButton.review" />
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" type="button" onClick={onCancel}>
            <FormattedMessage id="common.cancel" />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

RegisterProgressIndicatorForm.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  indicator: PropTypes.object.isRequired
};
