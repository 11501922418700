import React, { useState } from 'react';
import { CardContent, makeStyles, Table, TableBody, TableHead, Typography } from '@material-ui/core';
import { Card } from '../../components/atoms/Card';
import { TableRow } from '../../components/atoms/TableRow';
import { HeadCell } from '../../components/molecules/Table/HeadCell';
import { COLUMNS_WIDTH_SETTINGS } from './components/project/Budget/constants';
import { BasicTableCell } from '../../components/molecules/Table/BasicTableCell';
import { LinkButton } from '../../components/atoms/LinkButton';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Chip } from '../../components/atoms/Chip';
import { Avatar } from '../../components/atoms/Avatar';
import { palette } from '../../settings/themes/colors';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { IndicatorHistoryProgressModal } from './IndicatorHistoryProgressModal';

const useStyles = makeStyles({
  root: {
    '& *[class^="MuiTableCell-root"]': {
      color: 'white'
    }
  },
  containerRow: {
    borderBottom: `1px solid ${palette.darkGray20}`
  }
});

export const STATUS = Object.freeze({
  DRAFT: 'draft',
  APPROVED: 'approved',
  REJECTED: 'rejected'
});

const statusIcons = {
  draft: <RemoveRedEyeOutlinedIcon style={{ color: palette.dimGray }} />,
  rejected: <CancelIcon style={{ color: '#F13434' }} />,
  approved: <CheckCircleIcon style={{ color: '#43BC64' }} />
};

export const IndicatorDetailTable = ({ indicator, indicatorProgresses, onSuccess }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [selectedProgress, setSelectedProgress] = useState([]);
  const { goal, measurementUnit } = indicator;

  const handleOpenModal = progress => {
    setSelectedProgress(progress);
    setOpenModal(true);
  };

  if (indicatorProgresses.length === 0) {
    return (
      <Card className={classes.root}>
        <CardContent>
          <Typography variant={'subtitle1'} style={{ color: palette.captudataBlue }}>
            <FormattedMessage id="project.indicator.table.form.title.detailTable" />
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Table>
          <TableHead>
            <Typography variant={'subtitle1'} style={{ color: palette.captudataBlue }}>
              <FormattedMessage id="project.indicator.table.form.title.detailTable" />
            </Typography>
            <TableRow>
              <HeadCell>
                <FormattedMessage id="common.date" />
              </HeadCell>
              <HeadCell width={COLUMNS_WIDTH_SETTINGS.actionCell}>
                <FormattedMessage id="common.status" />
              </HeadCell>
              <HeadCell width={COLUMNS_WIDTH_SETTINGS.otherCells}>
                <FormattedMessage id="projectInfo.log.progress" />
              </HeadCell>
              <HeadCell width={COLUMNS_WIDTH_SETTINGS.otherCells}>
                <FormattedMessage id="common.responsible" />
              </HeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indicatorProgresses.map(({ effectiveDate, status, responsible, value, ...progress }, index) => (
              <TableRow key={index} className={classes.containerRow}>
                <BasicTableCell>
                  <Typography>
                    <LinkButton onClick={() => handleOpenModal(progress)}>{effectiveDate}</LinkButton>
                  </Typography>
                </BasicTableCell>
                <BasicTableCell>{statusIcons[status]}</BasicTableCell>
                <BasicTableCell>
                  <Chip
                    type="active"
                    label={
                      <FormattedMessage
                        id="project.indicator.progress.value"
                        values={{
                          value: value || 0,
                          goal,
                          unit: measurementUnit
                        }}
                      />
                    }
                  />
                </BasicTableCell>
                <BasicTableCell>
                  <Avatar responsible={responsible.fullName} variant="primary" />{' '}
                </BasicTableCell>
              </TableRow>
            ))}
            {selectedProgress.id && (
              <IndicatorHistoryProgressModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                indicatorProgress={selectedProgress}
                indicator={indicator}
                onSuccess={onSuccess}
              />
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

IndicatorDetailTable.propTypes = {
  indicator: PropTypes.shape({
    id: PropTypes.number,
    goal: PropTypes.number,
    measurementUnit: PropTypes.string
  }),
  indicatorProgresses: PropTypes.array
};
