import React from 'react';
import MuiAvatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { palette } from '../../settings/themes/colors';

const getInitialsName = name => {
  const [firstName, lastName] = name.split(' ');

  return `${firstName[0]}${lastName ? lastName[0] : ''}`;
};

const styles = makeStyles({
  root: {
    fontSize: 12,
    border: '2px solid transparent',
    '&.primary': {
      background: palette.lavender,
      borderColor: palette.artic
    },
    '&.secondary': {
      background: palette.violet,
      borderColor: palette.artic
    }
  },
  small: {
    width: 24,
    height: 24,
    fontSize: 10
  },
  medium: {
    width: 40,
    height: 40
  }
});

const useAvatarStyles = () => styles();

export const SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

export const AVATAR_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const Avatar = ({ responsible: fullName, size = SIZE.MEDIUM, variant = AVATAR_VARIANTS.PRIMARY, ...props }) => {
  const classes = useAvatarStyles();
  const initials = getInitialsName(fullName);

  return (
    <MuiAvatar className={classNames(classes.root, classes[size], variant)} {...props}>
      {initials}
    </MuiAvatar>
  );
};

Avatar.propTypes = {
  responsible: PropTypes.string,
  size: PropTypes.oneOf([SIZE.SMALL, SIZE.MEDIUM]),
  variant: PropTypes.oneOf(['primary', 'secondary'])
};
