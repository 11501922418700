import React from 'react';
import { Card } from '../atoms/Card';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CardContent, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { LinkButton } from '../atoms/LinkButton';
import { palette } from '../../settings/themes/colors';
import { FormattedMessage, useIntl } from 'react-intl';
import { IndicatorProgressImageSlider } from './IndicatorProgressImageSlider';
import { useDownloadIndicatorProgressDocument, useDownloadIndicatorProgressDocuments } from '../../queries/queryIndicatorProgresses';
import PropTypes from 'prop-types';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

const useStyles = makeStyles({
  itemDocument: {
    cursor: 'pointer',
    color: palette.cerulean,
    padding: lightThemeV2.spacing(1),
    borderRadius: 4,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: palette.darkGray20
    }
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: palette.dimGray
  },
  icon: {
    color: palette.cerulean
  }
});

export const ListDownloadDocuments = ({ indicatorProgressDetail = {} }) => {
  const { messages: intlMessages } = useIntl();
  const { documents = [], id: indicatorProgressId, indicatorId } = indicatorProgressDetail;
  const downloadAllDocuments = useDownloadIndicatorProgressDocuments();
  const downloadSingleDocument = useDownloadIndicatorProgressDocument();
  const handleDownLoadAllDocument = () => {
    if (indicatorId && indicatorProgressId) {
      downloadAllDocuments.mutate({ indicatorId, indicatorProgressId });
    }
  };
  const handleDownloadDocument = documentId => {
    if (indicatorProgressId && documentId) {
      downloadSingleDocument.mutate({ indicatorProgressId, documentId });
    }
  };

  const classes = useStyles();

  if (documents.length === 0) {
    return (
      <Card>
        <CardContent>
          <Typography variant="body2" color="textSecondary" align="center">
            <FormattedMessage id="project.indicator.progress.documents.noAvailable" />
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const imageExtension = ['png', 'jpg', 'jpeg', 'gif'];
  const imageDocuments = documents.filter(({ fileAttached }) => imageExtension.includes(fileAttached.type));

  return (
    <Grid container spacing={2}>
      {imageDocuments.length > 0 && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <IndicatorProgressImageSlider documents={imageDocuments} indicatorProgressId={indicatorProgressId} />
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} className={classes.root}>
                <div>
                  <Typography variant="body1">
                    <FormattedMessage id="list.download.documents.attached" />
                  </Typography>
                  <Typography variant="caption">
                    {documents.length} <FormattedMessage id="list.download.documents.attached" />
                  </Typography>
                </div>
                <LinkButton onClick={handleDownLoadAllDocument}>{(intlMessages['common.download.all'] || '').toUpperCase()}</LinkButton>
              </Grid>
              {documents.map(document => (
                <Grid
                  container
                  key={document.id}
                  onClick={() => handleDownloadDocument(document.id)}
                  className={classes.itemDocument}
                  alignItems="center"
                >
                  <Grid item xs={10}>
                    <Typography>{document.name}</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ justifyContent: 'end', display: 'flex', alignItems: 'center' }}>
                    <GetAppIcon className={classes.icon} />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

ListDownloadDocuments.propTypes = {
  indicatorProgressDetail: PropTypes.object.isRequired
};
